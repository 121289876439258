import { type ComponentType, lazy, type ReactNode } from "react"

const CreateGamePage = lazy(() =>
  import("./CreateGame").then((module) => ({
    default: module.CreateGamePage,
  }))
)

const MainPage = lazy(() =>
  import("./Main").then((module) => ({
    default: module.MainPage,
  }))
)

const GameWrapper = lazy(() =>
  import("./Game").then((module) => ({
    default: module.GameWrapper,
  }))
)

const DailyPage = lazy(() =>
  import("./Daily").then((module) => ({
    default: module.DailyPage,
  }))
)

// const ComingSoonPage = lazy(() =>
//   import("./ComingSoon").then((module) => ({
//     default: module.ComingSoonPage,
//   }))
// )

const CustomsPage = lazy(() =>
  import("./Customs").then((module) => ({
    default: module.CustomsPage,
  }))
)

const ProfilePage = lazy(() =>
  import("./Profile").then((module) => ({
    default: module.ProfilePage,
  }))
)

const LeaderboardPage = lazy(() =>
  import("./Leaderboard").then((module) => ({
    default: module.LeaderboardPage,
  }))
)

const ExchangeTokensPage = lazy(() =>
  import("./ExchangeTokens").then((module) => ({
    default: module.ExchangeTokensPage,
  }))
)

interface Route {
  Component: ComponentType
  icon?: ReactNode
  path: string
  title?: string
}

export const routes: Route[] = [
  { path: "/", Component: MainPage },
  { path: "/create", Component: CreateGamePage },
  { path: "/game", Component: GameWrapper },
  { path: "/daily", Component: DailyPage },
  { path: "/customs", Component: CustomsPage },
  // { path: "/customs", Component: ComingSoonPage },
  { path: "/profile", Component: ProfilePage },
  { path: "/leaderboard", Component: LeaderboardPage },
  { path: "/exchange", Component: ExchangeTokensPage },
]
