import { useInitData } from "@telegram-apps/sdk-react"
import { useContext } from "react"
import type { MessageInit } from "@durak/common"

import { EmojisContext } from "@/app/providers/EmojisProvider"
import { CloseIcon } from "@/shared/assets/icons"
import { useDimension } from "@/shared/store/dimensions-store"
import { useGame } from "@/shared/store/game-store"

export const Emojis = () => {
  const room = useGame((state) => state.room)
  const { cardHeight } = useDimension((state) => state.dimension)
  const { closeEmoji, sendEmoji } = useContext(EmojisContext)
  const initData = useInitData()
  const emojis = JSON.parse(localStorage.getItem("emojis") ?? "") as string[]

  return (
    <div
      style={{
        bottom: `${cardHeight * 0.34}px`,
        width: "98%",
      }}
      className="bg-card absolute left-1/2 flex -translate-x-1/2 items-center justify-center rounded-3xl"
    >
      {emojis &&
        emojis.map((emoji) => (
          <button
            type="button"
            key={emoji}
            className="rounded-lg px-0.5 py-2"
            onClick={() => {
              sendEmoji()

              localStorage.setItem(`${initData?.user?.id}_lastUsedEmoji`, emoji)

              room.send("game", {
                emoji,
                type: "sendEmoji",
              } as MessageInit)
            }}
          >
            <img
              src={`/emoji/${emoji}.svg`}
              width={cardHeight * 0.15}
              height={cardHeight * 0.15}
              alt={emoji}
            />
          </button>
        ))}

      <button
        aria-label="close"
        type="button"
        className="h-6 w-6"
        onClick={() => closeEmoji()}
      >
        <CloseIcon className="h-6 w-6" />
      </button>
    </div>
  )
}
